var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-section', {
    staticClass: "main-section--expertise py-0 mt-md-n2"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "main-section--expertise__left"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Expertise")];
      },
      proxy: true
    }])
  }, [_vm._v(" 태성 이혼가사센터 ")]), _c('div', {
    staticClass: "d-flex d-md-block justify-end"
  }, [_c('btn-secondary', {
    attrs: {
      "to": "/taesung/center",
      "data-aos": "zoom-out",
      "data-aos-delay": "150"
    }
  })], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "main-section--expertise__right"
  }, [_c('main-expertise')], 1)])], 1)], 1), _c('main-section', {
    staticClass: "main-section--media",
    scopedSlots: _vm._u([{
      key: "mainSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "media-bg media-bg--1 w-100",
          attrs: {
            "src": "/images/main/media-bg2.svg",
            "aspect-ratio": "1840 / 480"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    staticClass: "main-section--media__left",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Media")];
      },
      proxy: true
    }])
  }, [_vm._v(" 미디어 속 태성 ")]), _c('div', {
    staticClass: "d-flex d-md-block justify-end"
  }, [_c('btn-secondary', {
    attrs: {
      "to": "/media",
      "data-aos": "zoom-out",
      "data-aos-delay": "150"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('main-media')], 1)], 1)], 1), _c('main-section', {
    staticClass: "main-section--introduction"
  }, [_c('tit-wrap-primary', {
    staticClass: "text-center",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Introduction")];
      },
      proxy: true
    }])
  }, [_vm._v(" 변호사 소개 ")]), _c('main-introduction')], 1), _c('main-section', {
    staticClass: "main-section--reference grey lighten-5"
  }, [_c('u-tit-wrap-default', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-primary', {
    staticClass: "pb-md-0",
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Reference")];
      },
      proxy: true
    }])
  }, [_vm._v(" 태성 이혼가사센터 성공사례 ")])], 1)], 1)], 1), _c('main-reference')], 1), _c('main-section', {
    staticClass: "main-section--about"
  }, [_c('tit-wrap-primary', {
    staticClass: "text-center",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Lawfirm")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "font-weight-regular secondary--text text--lighten-4"
  }, [_vm._v(" 태성 "), _c('strong', {
    staticClass: "white--text"
  }, [_vm._v("이혼가사센터")]), _vm._v("는 오직! "), _c('strong', {
    staticClass: "white--text"
  }, [_vm._v("이혼 사건만 합니다.")]), _vm._v(" "), _c('br'), _c('strong', {
    staticClass: "white--text"
  }, [_vm._v("13년의 사건 진행 경험")]), _vm._v("을 통해 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 의뢰인의 상황에 "), _c('strong', {
    staticClass: "white--text"
  }, [_vm._v("알맞은 법률 솔루션")]), _vm._v("을 전해드립니다. ")])]), _c('main-about')], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }