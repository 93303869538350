export const lawyer = {
    lawyer01: {
        title: "최유나",
        titleEn: "Choi Yuna",
        info: [
            {
                title: "약력",
                infoDetail: [
                    {
                        txt: "법무법인 태성 대표변호사",
                        bold: true,
                    },
                    "대한변호사협회 등록 이혼전문변호사 (3,000건 이상 진행)",
                    "대한변호사협회 등록 가사법전문변호사",
                    "한국심리학회 자문 변호사",
                    "SBS 법정드라마 ‘굿파트너‘ 집필",
                    "25만 명 구독 법률인스타툰 <메리지레드> 연재",
                    "베스트셀러 <혼자와 함께 사이>, <우리 이만 헤어져요> 집필",
                    "tvN ‘유퀴즈 온더 블록‘ 이혼전문변호사로 출연",
                    "CBS ‘세상을 바꾸는 시간 15분’ 강연 200만 뷰 달성",
                    "SBS ‘돌싱포맨’, KBS ‘아침마당’ 등 다수 방송 출연",
                    "前 네이버 연애결혼, 법률판 자문",
                    "前 매일경제 칼럼리스트",
                    "그 외 CBS ‘시사자키’, 언론사 ‘주간동아’, ‘여성동아’, ‘한국일보’, ‘조선일보’ 인터뷰 진행",
                    //
                ],
            },
            {
                title: "업무 범위",
                infoDetail: [
                    "이혼, 사실혼 위자료, 재산분할, 양육권 등 수백여건 소송 진행",
                    "상속유류분소송 담당 변호사",
                    "내연남, 내연녀 위자료청구",
                    "가정폭력, 접근금지가처분",
                    "혼인무효, 취소 소송",
                    "유류분, 기여분 및 상속재산분쟁",
                    "친자관계소송 (친생부인, 친자관계존부확인, 인지청구 등)",
                    "국제이혼, 다문화 가정 이혼",
                    //
                ],
            },
        ],
        ceo: true,
    },

    lawyer02: {
        title: "문종하",
        titleEn: "Moon Jongha",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회등록 이혼전문변호사",
                    "네이버 지식인 상담 변호사 (이혼, 가사 전문)",
                    "전)법무법인 서우",
                    "전)법무법인 인성",
                    "전)봄날가정법률사무소",
                    "전)법무법인 혜안",
                    "전)중소기업 법률자문단",
                    //
                ],
            },
        ],
    },

    lawyer03: {
        title: "이준희",
        titleEn: "Lee Junhee",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 이혼전문변호사",
                    "제47회 세무사 시험 합격",
                    "경희대학교 국제경영학과 졸업",
                    "서울시립대학교 법학전문대학원 졸업",
                    "전) 대한가정법률복지상담원",
                    "전) 법무법인 선한 변호사",
                    "전)법무법인 청조 실무수습",
                    //
                ],
            },
        ],
    },

    lawyer04: {
        title: "정지은",
        titleEn: "Chung Jieun",
        info: [
            {
                title: "소개글",
                text: "의뢰인의 상황을 세심하게 분석하고, 그에 맞는 최적의 해결책을 제시하여 최상의 결과를 도출하겠습니다. 법적 문제를 해결하는 과정에서 든든한 법률 동반자로서, 더 나은 내일을 준비할 수 있도록 돕겠습니다.",
            },
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "한국가족법학회 회원",
                    "국제이혼소송 전담 변호사 (영어 상담 가능)",
                    "고려대학교 자유전공학부 정치외교학과 졸업",
                    "서울동부지방검찰청 실무 수습",
                    //
                ],
            },
        ],
    },

    lawyer05: {
        title: "정우중",
        titleEn: "Jung Woojoong",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 등록 이혼전문변호사",
                    "광주지방경찰청 인권위원",
                    "전남대학교 법과대학 및 법학전문대학원 졸업",
                    //
                ],
            },
        ],
    },

    lawyer06: {
        title: "임정훈",
        titleEn: "lim Junghoon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 등록 이혼전문변호사",
                    "광주지방검찰청 범죄피해자지원센터 법률지원위원",
                    "광주지방법원 법인파산관재인",
                    "전) 변호사 노로·임정훈 법률사무소",
                    "전) 한국자산관리공사 근무",
                    //
                ],
            },
        ],
    },

    lawyer07: {
        title: "김태은",
        titleEn: "Kim Taeeun",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 이혼전문변호사",
                    "전) 법무법인 운율 소속 변호사",
                    "전) 인천광역시 교육청행정심판위원회 국선대리인",
                    "전) 인천 미추홀경찰서 현장 인권 상담위원",
                    "현) 인천남동경찰서 상담변호사",
                    //
                ],
            },
        ],
    },

    lawyer08: {
        title: "윤영환",
        titleEn: "Yoon Younghwan",
        info: [
            {
                title: "약력",
                infoDetail: [
                    {
                        txt: "법무법인 태성 대표변호사",
                        bold: true,
                    },
                    "고려대학교 졸업 (건설 공학 전공)",
                    "건축시공기술사",
                    "토목기사",
                    "대한변호사협회 건설전문변호사",
                    "대한변호사협회 부동산전문변호사",
                    "전) 국회 하자 정책토론위원",
                    "전) 한국건설기술연구원 시민감사관",
                    "전) 건설이코노미뉴스 칼럼니스트 ",
                    "네이버 지식iN 건설 전문가 상담 위원",
                    "공동주택하자소송 400여 건 담당",
                    "MBC 등 언론사 법률자문 제공",
                    "대한주택관리사협회 경기도회 강의",
                    "대한주택관리사협회 광주시회 강의",
                    //
                ],
            },
        ],
        ceo: true,
    },

    lawyer09: {
        title: "박규훈",
        titleEn: "Park Kyuhoon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사사건 담당변호사",
                    "상속·유류분 담당 변호사",
                    "전) 팜팩토리 법률 자문 변호사",
                    "전) 예그리나 법률 자문 변호사",
                    "인하대학교 법학과 졸업",
                    "전북대학교 법학전문대학원 졸업",
                    "사법연수원 하계연수",
                    //
                ],
            },
        ],
    },

    lawyer10: {
        title: "남덕호",
        titleEn: "Nam Deokho",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼 등 가사소송 담당 변호사",
                    "연세대학교 법학전문대학원",
                    "인천지방법원 실무 수습",
                    "정부법무공단 실무 수습",
                    //
                ],
            },
        ],
    },

    lawyer11: {
        title: "구찬회",
        titleEn: "Koo Chanhoe",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼 등 가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "홍익대학교 법학과 졸업",
                    //
                ],
            },
        ],
    },

    lawyer12: {
        title: "원혜원",
        titleEn: "Won Hyewon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "부산대학교 법학전문대학원 졸업",
                    "전) 법무법인 영동 변호사",
                    "전) 법무법인 올흔 자문 변호사",
                    //
                ],
            },
        ],
    },

    lawyer13: {
        title: "정수진",
        titleEn: "Jung Sujin",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "한양대학교 정책학과 우수 졸업",
                    "한양대학교 법학전문대학원",
                    "헌법재판연구원 실무수습",
                    "수원지방검찰청 안양지청 실무수습",
                    "전) 법무법인 중현",
                    "전) 법무법인(유한) 대륜 본사",
                    //
                ],
            },
        ],
    },

    lawyer14: {
        title: "구경일",
        titleEn: "Ku Kyeongil",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "고려대학교 건축사회환경공학부 졸업",
                    "경북대학교 법학전문대학원 졸업",
                    "대구지방법원 하계 실무수습",
                    //
                ],
            },
        ],
    },

    lawyer15: {
        title: "박정빈",
        titleEn: "Park Jungbin",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "이혼가사소송 담당 변호사",
                    "상속·유류분 담당 변호사",
                    "손해사정사",
                    "한국외국어대학교 경제학과 졸업",
                    "아주대학교 법학전문대학원 졸업",
                    "전) 호반건설 회계팀",
                    "전) 법무법인 테헤란",
                    //
                ],
            },
        ],
    },

    lawyer16: {
        title: "배지원",
        titleEn: "Bae Jiwon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "서울대 졸업",
                    "사법시험 제43회(사법연수원 33기)",
                    "법무법인 태성 공증 변호사",
                    "이혼가사소송 담당 변호사 ",
                    "상속·유류분 담당 변호사 ",
                    "전) 강남구청 무료 상담 변호사",
                    "전) (주)엠포스 고문 변호사",
                    "전) (주)빌리어즈TV 고문 변호사",
                    "전) (주)엠피아 고문 변호사",
                    "전) (주)골프넷 고문 변호사",
                    "전) (주)웨인테크놀로지 고문 변호사",
                    "전) (주)대산 고문 변호사",
                    "전) (주)시소캐피탈 고문 변호사",
                    //
                ],
            },
        ],
    },
};
